import React, { useEffect, useState } from 'react';
import { Card, ErrorSummary, Button, Checkboxes, SummaryList } from "nhsuk-react-components";
import { useDispatch, useSelector } from 'react-redux';
import { listAetiologies, listAetiologiesByIds } from '../../../actions/aetiologyActions';
import { listSymptoms, listSymptomsByIds } from '../../../actions/symptomActions';
import { listEcgs, listEcgsByIds } from '../../../actions/ecgActions';
import { listConsents } from '../../../actions/consentActions';
import { updatePatientConsentIds } from '../../../actions/patientActions';
import EntitySelect from '../../../components/entitySelect/EntitySelect.component';
import Modal from '../../../components/modal/Modal.component';
import { convertNewlinesToBreaks } from '../../../utils/functions';

const ClinicalIndications = ({
    updatedPatient,
    onPatientClinicalIndicationChange,
    error,
}) => {
    const dispatch = useDispatch();
    const [consentModalShow, setConsentModalShow] = useState(false);
    const [consentModalContent, setConsentModalContent] = useState();

    const consentList = useSelector((state) => state.consentList);
    const { loading: loadingConsents, error: errorConsents, consents } = consentList;

    useEffect(() => {
        dispatch(listConsents());
    }, [dispatch]);

    const handleAetiologyChange = (selectedIds) => {
        onPatientClinicalIndicationChange({ ...updatedPatient, aetiologyIds: selectedIds });
    };

    const handleSymptomChange = (selectedIds) => {
        onPatientClinicalIndicationChange({ ...updatedPatient, symptomIds: selectedIds });
    };

    const handleEcgChange = (selectedIds) => {
        onPatientClinicalIndicationChange({ ...updatedPatient, ecgIds: selectedIds });
    };

    const handleConsentChange = (consentId, isChecked) => {
        const currentConsentIds = updatedPatient?.consentIds || [];
        const updatedConsentIds = isChecked
            ? [...currentConsentIds, consentId]
            : currentConsentIds.filter(id => id !== consentId);

        if (isChecked) {
            const consent = consents.find(consent => consent.id === consentId);
            if (consent.consentType === "Full Access") {
                // Automatically check all other consents without showing their popups
                const allConsentIds = consents.map(consent => consent.id);
                onPatientClinicalIndicationChange({ ...updatedPatient, consentIds: allConsentIds });
                handleConsentClick(consentId);
            } else {
                onPatientClinicalIndicationChange({ ...updatedPatient, consentIds: updatedConsentIds });
                handleConsentClick(consentId);
            }
        } else {
            const consent = consents.find(consent => consent.id === consentId);
            if (consent.consentType === "Full Access") {
                // Uncheck all consents when Full Access is unchecked
                onPatientClinicalIndicationChange({ ...updatedPatient, consentIds: [] });
            } else {
                onPatientClinicalIndicationChange({ ...updatedPatient, consentIds: updatedConsentIds });
            }
        }
    };

    const handleIsConsentingPatientChange = (isChecked) => {
        if (!isChecked) {
            // Uncheck all consents when isConsentingPatient is unchecked
            onPatientClinicalIndicationChange({ 
                ...updatedPatient, 
                isConsentingPatient: false, 
                consentIds: [],
                dateConsented: null
            });
        } else {
            // Update dateConsented when isConsentingPatient is checked
            const currentDate = new Date().toISOString();
            onPatientClinicalIndicationChange({ 
                ...updatedPatient, 
                isConsentingPatient: true,
                dateConsented: currentDate
            });
        }
    };

    const openConsentModal = () => {
        setConsentModalShow(true);
    };

    const closeConsentModal = () => {
        setConsentModalShow(false);
    };

    const handleConsentClick = (consentId) => {
        const consent = consents.find(consent => consent.id === consentId);
        setConsentModalContent(consent);
        openConsentModal();
    };

    const handleConsentRefuse = (consentId) => {
        const consent = consents.find(consent => consent.id === consentId);
        if (consent.consentType === "Full Access") {
            // Uncheck all consents when Full Access is refused
            onPatientClinicalIndicationChange({ ...updatedPatient, consentIds: [] });
        } else {
            const currentConsentIds = updatedPatient?.consentIds || [];
            const updatedConsentIds = currentConsentIds.filter(id => id !== consentId);
            onPatientClinicalIndicationChange({ ...updatedPatient, consentIds: updatedConsentIds });
        }
        closeConsentModal();
    };

    const consentPropertiesToIgnore = ["id", "patientConsents", "consentDate", "consentAdditionalInfo"];

    console.log(updatedPatient);

    return (
        <Card>
            <Card.Content>
                <Card.Heading className="nhsuk-heading-m">
                    Clinical Indications
                </Card.Heading>
                {error && (
                    <ErrorSummary>
                        <ErrorSummary.Title id="error-summary-title">
                            There is a problem
                        </ErrorSummary.Title>
                        <ErrorSummary.Body>
                            <ul className="nhsuk-list nhsuk-list--bullet">
                                {error.map((err, index) => (
                                    <li key={index}>{err}</li>
                                ))}
                            </ul>
                        </ErrorSummary.Body>
                    </ErrorSummary>
                )}

                <EntitySelect
                    title={"Aetiologies"}
                    singularEntity="aetiology"
                    pluralEntity="aetiologies"
                    constantPrefix="AETIOLOGY"
                    listAction={listAetiologies}
                    listByIdsAction={listAetiologiesByIds}
                    selectedIds={updatedPatient.aetiologyIds || []}
                    onChange={(selectedAetiologies) => {
                        const aetiologyIds = selectedAetiologies?.map(aetiology => aetiology.id) || [];
                        onPatientClinicalIndicationChange({
                            ...updatedPatient,
                            aetiologyIds
                        });
                    }}
                    summaryKeys={['name', 'description']}
                />

                <EntitySelect
                    title={"Symptoms"}
                    singularEntity="symptom"
                    pluralEntity="symptoms"
                    constantPrefix="SYMPTOM"
                    listAction={listSymptoms}
                    listByIdsAction={listSymptomsByIds}
                    selectedIds={updatedPatient?.symptomIds || []}
                    onChange={(selectedSymptoms) => {
                        const symptomIds = selectedSymptoms.map(symptom => symptom.id) || [];
                        onPatientClinicalIndicationChange({
                            ...updatedPatient,
                            symptomIds,
                            symptomEntities: selectedSymptoms
                        });
                    }}
                    summaryKeys={['name', 'description']}
                />

                <EntitySelect
                    title={"ECG indications"}
                    singularEntity="ecg"
                    pluralEntity="ecgs"
                    constantPrefix="ECG"
                    listAction={listEcgs}
                    listByIdsAction={listEcgsByIds}
                    selectedIds={updatedPatient?.ecgIds || []}
                    onChange={(selectedEcgs) => {
                        const ecgIds = selectedEcgs.map(ecg => ecg.id) || [];
                        onPatientClinicalIndicationChange({
                            ...updatedPatient,
                            ecgIds,
                            ecgEntities: selectedEcgs
                        });
                    }}
                    summaryKeys={['name', 'detail']}
                />

                <div style={{ marginTop: '50px' }}>
                    <Checkboxes.Box
                        key={"isConsentingPatient" - updatedPatient.id}
                        checked={updatedPatient?.isConsentingPatient}
                        onChange={(e) => handleIsConsentingPatientChange(e.target.checked)}
                    >
                        I consent to Barts Health NHS Trust staff accessing my implanted device information.
                    </Checkboxes.Box>

                    {updatedPatient?.isConsentingPatient && (
                        <Checkboxes name="checklist" hint="I also consent to the following:" heading={"Patient Consents"} expand={false}>
                            {loadingConsents ? (
                                <p>Loading consents...</p>
                            ) : errorConsents ? (
                                <p>Error loading consents: {errorConsents}</p>
                            ) : (
                                consents
                                    ?.sort((a, b) => a.consentType === "Full Access" ? -1 : b.consentType === "Full Access" ? 1 : 0)
                                    .map(consent => (
                                        <Checkboxes.Box
                                            key={consent.id}
                                            conditional={`Scope: ${consent.consentScope}`}
                                            checked={(updatedPatient?.consentIds || []).includes(consent.id)}
                                            onChange={(e) => handleConsentChange(consent.id, e.target.checked)}
                                        >
                                            {consent.consentType}
                                        </Checkboxes.Box>
                                    ))
                            )}
                        </Checkboxes>
                    )}
                </div>
            </Card.Content>

            <Modal show={consentModalShow} handleClose={closeConsentModal}>
                <h2>{consentModalContent?.consentType}</h2>
                {consentModalContent && (
                    <SummaryList>
                        {Object.entries(consentModalContent).map(([key, value]) => (
                            !consentPropertiesToIgnore.includes(key) && (
                                <SummaryList.Row key={key}>
                                    <SummaryList.Key>
                                        {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                                    </SummaryList.Key>
                                    <SummaryList.Value>
                                        {typeof value === 'string' ? convertNewlinesToBreaks(value) : (value ? value.toString() : "-")}
                                    </SummaryList.Value>
                                </SummaryList.Row>
                            )
                        ))}
                    </SummaryList>
                )}
                <Button onClick={() => closeConsentModal()}>
                    I Agree to the above
                </Button>
                {" "}
                <Button secondary onClick={() => handleConsentRefuse(consentModalContent?.id)}>
                    Refuse
                </Button>
            </Modal>
        </Card>
    );
};

export default ClinicalIndications;