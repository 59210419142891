import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, ActionLink, Label, Tag, Input, Fieldset, Radios, BackLink, Card, Details, Button, DateInput, InsetText, Textarea, ErrorSummary, Select, Checkboxes } from 'nhsuk-react-components';
import { createImplantInstance, updateImplantInstance, deleteImplantInstance, getImplantInstanceDetails, listImplantInstancesByPatientNhsNumber } from '../../actions/implantInstanceActions';
import { getImplantDetailsByBarcode } from '../../actions/implantActions';
import { parseBarcode } from '../../utils/functions';

// constants 
import { IMPLANT_INSTANCE_CREATE_RESET, IMPLANT_INSTANCE_UPDATE_RESET } from '../../constants/implantInstanceConstants';

import AppSpinner from '../spinner/AppSpinner.component';

import Callout from '../callout/Callout.component';
import SelectOther from '../selectOther/SelectOther.component';
import Modal from '../modal/Modal.component';
import ISODateInput from '../date/ISODateInput.component';

const ImplantInstanceList = ({ patientNHSNumber, patientFullName, onDeviceListConditionChange }) => {

    // use react hook Dispatch to dispatch actions
    const dispatch = useDispatch();


    // reset the state of the implantInstanceCreate and implantInstanceUpdate
    useEffect(() => {
        dispatch({ type: IMPLANT_INSTANCE_CREATE_RESET });
        dispatch({ type: IMPLANT_INSTANCE_UPDATE_RESET });
    }, [dispatch]);

    // set initial filter to empty string, set default value 
    // get implantInstance list from redux store
    const implantInstanceListByNhsNumber = useSelector((state) => state.implantInstanceListByNhsNumber);
    const { implantInstances, loading, error, currentPage, totalPages } = implantInstanceListByNhsNumber;

    // get implantInstance create from redux store
    const implantInstanceCreate = useSelector((state) => state.implantInstanceCreate);
    const { success: successCreate, error: implantInstanceCreateErrors } = implantInstanceCreate;

    // get implantInstance update from redux store
    const implantInstanceUpdate = useSelector((state) => state.implantInstanceUpdate);
    const { success: successUpdate, loading: implantInstanceUpdateProcessing } = implantInstanceUpdate;

    // get implantInstance delete from redux store
    const implantInstanceDelete = useSelector((state) => state.implantInstanceDelete);
    const { success: successDelete } = implantInstanceDelete;

    // get implantInstance details from redux store
    const implantInstanceDetails = useSelector((state) => state.implantInstanceDetails);
    const { implantInstance: implantInstanceDet, loading: implantInstanceDetailsLoading } = implantInstanceDetails;

    // get implant details from redux store
    const implantDetailsByBarcode = useSelector((state) => state.implantDetailsByBarcode);
    const { implant, loading: loadingImplantDetailsByBarcode, error: errorImplantDetailsByBarcode, success: successImplantDetailsByBarcode } = implantDetailsByBarcode;

    // newImplantInstance state
    const [newImplantInstance, setNewImplantInstance] = useState({});
    const [updatedImplantInstance, setUpdatedImplantInstance] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [isLead, setIsLead] = useState(true);
    const [isGenerator, setIsGenerator] = useState(true);
    const [autoFillDetail, setAutoFillDetail] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [isMRIConditional, setIsMRIConditional] = useState(false);
    const [isAbandonedHardware, setIsAbandonedHardware] = useState(false);
    const [deviceListCondition, setDeviceListCondition] = useState({ isAbandonedHardware: false, isMRIConditional: false });
    const [deviceListBarcodes, setDeviceListBarcodes] = useState([]);
    const [manualImplantEdit, setManualImplantEdit] = useState(false);
    const [scannedBarcode, setScannedBarcode] = useState('');
    const [modelNotFound, setModelNotFound] = useState(false);


    // listen to filter changes
    useEffect(() => {
        dispatch(listImplantInstancesByPatientNhsNumber(patientNHSNumber));
    }, [dispatch, patientNHSNumber]);

    // listen to implantInstanceDet 
    useEffect(() => {
        if (implantInstanceDet) {
            // use spread operator to copy the implantInstanceDet object
            setUpdatedImplantInstance({ ...implantInstanceDet });
        }
    }, [implantInstanceDet]);

    // listen to editMode
    useEffect(() => {
        if (editMode === false) {
            // empty the newImplantInstance state
            handleResetNewImplantDetails();
        }
    }, [editMode]);

    // listen to Implant details
    useEffect(() => {
        if (implant) {
            setModelNotFound(false);
            if (editMode) {
                setUpdatedImplantInstance({
                    ...updatedImplantInstance,
                    modelName: implant.modelName,
                    modelNumber: implant.modelNumber,
                    manufacturer: implant.manufacturer,
                    modelCategory: implant.modelCategory,
                    type: implant.type,
                    isMRIConditional: implant.mrConditional,
                    eventDate: new Date().toISOString(),
                });
                setManualImplantEdit(true);
            } else {
                setNewImplantInstance({
                    ...newImplantInstance,
                    modelName: implant.modelName,
                    modelNumber: implant.modelNumber,
                    manufacturer: implant.manufacturer,
                    modelCategory: implant.modelCategory,
                    type: implant.type,
                    isMRIConditional: implant.mrConditional,
                    eventDate: new Date().toISOString(),
                });
                setManualImplantEdit(true);
            }
        } else if (errorImplantDetailsByBarcode) {
            setModelNotFound(true);
            // Don't clear the barcode field
            if (editMode) {
                setUpdatedImplantInstance({
                    ...updatedImplantInstance,
                    modelName: '',
                    modelNumber: '',
                    manufacturer: '',
                    modelCategory: '',
                    type: '',
                    isMRIConditional: false,
                    eventDate: new Date().toISOString(),
                });
            } else {
                setNewImplantInstance({
                    ...newImplantInstance,
                    modelName: '',
                    modelNumber: '',
                    manufacturer: '',
                    modelCategory: '',
                    type: '',
                    isMRIConditional: false,
                    eventDate: new Date().toISOString(),
                });
            }
            setManualImplantEdit(false);
        }
    }, [implant, errorImplantDetailsByBarcode]);


    // lisen to successCreate
    useEffect(() => {
        if (successCreate) {
            handleResetNewImplantDetails();
            dispatch(listImplantInstancesByPatientNhsNumber(patientNHSNumber, {}));
        }
    }, [successCreate]);

    // listen to modelCategory in newImplantInstance
    useEffect(() => {
        if (newImplantInstance.modelCategory === 'Generator') {
            setIsGenerator(true);
            setIsLead(false);
            // clear the lead fields
            setNewImplantInstance({ ...newImplantInstance, leadChamber: '', pacingSite: '' });
        } else if (newImplantInstance.modelCategory === 'Lead') {
            setIsGenerator(false);
            setIsLead(true);
            // clear the generator fields
            setNewImplantInstance({ ...newImplantInstance, generatorImplantSite: '', maxSystemCapability: '' });
        } else {
            setIsGenerator(false);
            setIsLead(false);
            // clear the generator and lead fields
            setNewImplantInstance({ ...newImplantInstance, generatorImplantSite: '', maxSystemCapability: '', leadChamber: '', pacingSite: '' });
        }
    }, [newImplantInstance.modelCategory]);

    // listen to modelCategory in updatedImplantInstance
    useEffect(() => {
        if (updatedImplantInstance.modelCategory === 'Generator') {
            setIsGenerator(true);
            setIsLead(false);
            // clear the lead fields
            setUpdatedImplantInstance({ ...updatedImplantInstance, leadChamber: '', pacingSite: '' });
        } else if (updatedImplantInstance.modelCategory === 'Lead') {
            setIsGenerator(false);
            setIsLead(true);
            // clear the generator fields
            setUpdatedImplantInstance({ ...updatedImplantInstance, generatorImplantSite: '', maxSystemCapability: '' });
        } else {
            setIsGenerator(false);
            setIsLead(false);
            // clear the generator and lead fields
            setUpdatedImplantInstance({ ...updatedImplantInstance, generatorImplantSite: '', maxSystemCapability: '', leadChamber: '', pacingSite: '' });
        }
    }, [updatedImplantInstance.modelCategory]);

    // listen to implantInstances
    useEffect(() => {
        if (implantInstances) {
            // if at least one implant is MRI conditional, then the patient is MRI conditional
            const isMRIConditional = implantInstances.some(implantInstance => implantInstance.mrConditional && implantInstance.status.includes("Implanted"));
            const isAbandonedHardware = implantInstances.some(implantInstance => implantInstance.abandonedHardware);
            const deviceBarcodeList = implantInstances.map(implantInstance => implantInstance.barcode);
            setIsMRIConditional(isMRIConditional);
            setIsAbandonedHardware(isAbandonedHardware);
            setDeviceListCondition({ isAbandonedHardware: isAbandonedHardware, isMRIConditional: isMRIConditional, deviceBarcodeList: deviceBarcodeList, deviceList: implantInstances });
        }
    }, [implantInstances]);


    // listen to DeviceListCondition changes
    useEffect(() => {
        onDeviceListConditionChange(deviceListCondition);
    }, [deviceListCondition]);




    // handle delete
    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this implantInstance?')) {
            dispatch(deleteImplantInstance(id)).then(() => {
                dispatch(listImplantInstancesByPatientNhsNumber(patientNHSNumber, {}));
            }
            );
        }
    };

    // handle edit
    const handleEditImplantInstance = (id) => {
        dispatch(getImplantInstanceDetails(id));
        setEditMode(true);
        setShowModal(true);

    };

    // handle create
    const handleCreateImplantInstance = () => {
        dispatch(createImplantInstance(newImplantInstance));
    };

    // handle update
    const handleUpdateImplantInstance = () => {
        dispatch(updateImplantInstance(updatedImplantInstance.id, updatedImplantInstance)).then(() => {
            setUpdatedImplantInstance(updatedImplantInstance);
            setEditMode(true);
            dispatch(getImplantInstanceDetails(updatedImplantInstance.id));
            dispatch(listImplantInstancesByPatientNhsNumber(patientNHSNumber, {}));
        }
        );
    };
    // handle get implant by barcode
    const handleGetImplantByBarcode = (rawBarcode) => {

        const { gtin, expiry, serial } = parseBarcode(rawBarcode);

        if (editMode) {
            // update the updatedImplantInstance state
            setUpdatedImplantInstance({ ...updatedImplantInstance, barcode: gtin, expiryDate: expiry, serialNumber: serial });

        } else {
            // update the newImplantInstance state
            setNewImplantInstance({ ...newImplantInstance, barcode: gtin, expiryDate: expiry, serialNumber: serial });
        }

        dispatch(getImplantDetailsByBarcode(gtin));
    };

    const handleResetNewImplantDetails = () => {
        setNewImplantInstance({
            patientName: patientFullName || '',
            patientNHSNumber: patientNHSNumber || '',
            isFirstImplant: false,
            interventionCategory: '',
            modelName: '',
            modelNumber: '',
            barcode: '',
            manufacturer: '',
            modelCategory: '',
            type: '',
            eventDate: new Date().toISOString(),
            expiryDate: new Date().toISOString(),
            serialNumber: '',
            status: '',
            eventType: '',
            eventDetails: '',
            practitionerDetails: '',
            notes: '',
            generatorImplantSite: '',
            maxSystemCapability: '',
            leadChamber: '',
            pacingSite: '',
            abandonedHardware: false,
            mrConditional: false,
        });
    }

    const handleCreateImplantButtonClick = () => {
        handleResetNewImplantDetails();
        setEditMode(false);
        setShowModal(true);
    }


    return (
        <React.Fragment>
            <InsetText>
                <p>Only implanted devices will be checked for MRI conditionality.</p>
            </InsetText>

            <Button
                onClick={handleCreateImplantButtonClick}
            >
                + Add New Implant
            </Button>
            {isMRIConditional && (
                <Callout
                    label="MRI Conditional"
                    header="MRI Conditional"
                    time={new Date().toLocaleTimeString()}
                    title="MRI Conditional"
                    message="At least one of the implanted components is MRI conditional."
                    mode="warning"
                />
            )}
            {isAbandonedHardware && (
                <Callout
                    label="Abandoned Hardware"
                    header="Abandoned Hardware"
                    time={new Date().toLocaleTimeString()}
                    title="Abandoned Hardware"
                    message="At least one of the implanted components was abandoned."
                    mode="warning"
                />
            )}

            {loading ? (
                <AppSpinner />
            ) : error ? (
                <p>{error}</p>
            ) : (
                implantInstances && implantInstances.length > 0 ? (
                    implantInstances.map((implantInstance) => (
                        <Card clickable
                            key={implantInstance.barcode}
                            style={{ borderLeft: implantInstance.status === 'Implanted, connected' ? '10px solid green' : implantInstance.status === 'Explanted' ? '10px solid orange' : '10px solid #005eb8' }}
                        >
                            <Card.Content>
                                <Details>
                                    <Details.Summary>
                                        <Tag>{implantInstance.modelCategory}</Tag><br />
                                        {implantInstance.modelName} - ({implantInstance.serialNumber})
                                    </Details.Summary>
                                    <Details.Text>
                                        {implantInstance.modelCategory === 'Generator' && (
                                            <InsetText>
                                                <p><strong>Implant Site: </strong>{implantInstance.generatorImplantSite}</p>
                                                <p><strong>Max System Capability: </strong>{implantInstance.maxSystemCapability}</p>
                                            </InsetText>
                                        )}
                                        {implantInstance.modelCategory === 'Lead' && (
                                            <InsetText>

                                                <p><strong>Lead Chamber:</strong> {implantInstance.leadChamber}</p>
                                                <p><strong>Pacing Site:</strong> {implantInstance.pacingSite}</p>
                                            </InsetText>
                                        )}
                                        <p>Manufacturer: {implantInstance.manufacturer}</p>
                                        <p>Model Name: {implantInstance.modelName}</p>
                                        <p>Model Number: {implantInstance.modelNumber}</p>
                                        <p>Serial Number: {implantInstance.serialNumber}</p>
                                        <p>Date Implanted: {implantInstance.eventDate}</p>
                                        <p>
                                            Status: <Tag>{implantInstance.status}</Tag>
                                        </p>
                                        <p>
                                            Abandoned Hardware: <Tag color={implantInstance.abandonedHardware ? 'red' : 'green'}>{implantInstance.abandonedHardware ? 'Yes' : 'No'}</Tag>
                                        </p>
                                        <p>
                                            MR Conditional: <Tag color={implantInstance.mrConditional ? 'red' : 'green'}>{implantInstance.mrConditional ? 'Yes' : 'No'}</Tag>
                                        </p>
                                    </Details.Text>
                                </Details>

                                <div className="d-flex justify-content-between">
                                    <Button
                                        disabled={implantInstanceDetailsLoading}
                                        onClick={() =>
                                            handleEditImplantInstance(implantInstance.id)
                                        }
                                    >
                                        View/Amend
                                    </Button>
                                    {" "}
                                    <Button
                                        secondary

                                        onClick={() => handleDelete(implantInstance.id)}
                                    >
                                        Delete
                                    </Button>
                                </div>
                            </Card.Content>
                        </Card>
                    ))
                ) : (
                    <p>No Implants Found</p>
                )

            )}



            <Modal
                show={showModal}
                handleClose={() => setShowModal(false)}
                title="Select Implant"
            >
                {editMode ? (
                    <Card id='edit-card'>
                        <Card.Content>
                            <Card.Heading className="nhsuk-heading-m">
                                Edit Implant
                            </Card.Heading>

                            <Card.Description>
                                <Checkboxes name="checkboxes">

                                    <Checkboxes.Box
                                        checked={updatedImplantInstance?.isFirstImplant}
                                        onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, isFirstImplant: e.target.checked })}
                                    >
                                        First Implant?
                                    </Checkboxes.Box>
                                </Checkboxes>


                                <ISODateInput
                                    id="updateEventDateTime"
                                    label="Implantation Date"
                                    name="eventDateTime"
                                    value={updatedImplantInstance?.eventDate}
                                    onChange={(dt) => setUpdatedImplantInstance({ ...updatedImplantInstance, eventDate: dt })}
                                    required
                                />

                                <Select
                                    id="interventionCategory"
                                    label="Intervention Category"
                                    name="interventionCategory"
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        setUpdatedImplantInstance({ ...updatedImplantInstance, interventionCategory: selectedValue });
                                    }}
                                    value={updatedImplantInstance?.interventionCategory}
                                    required
                                >
                                    <Select.Option value="">Select an intervention category</Select.Option>
                                    <Select.Option value="No intervention (FU only of pt implanted elsewhere)">No intervention (FU only of pt implanted elsewhere)</Select.Option>
                                    <Select.Option value="First ever pacemaker/ICD system">First ever pacemaker/ICD system</Select.Option>
                                    <Select.Option value="Generator change only">Generator change only</Select.Option>
                                    <Select.Option value="New generator and lead(s) revision/replacement">New generator and lead(s) revision/replacement</Select.Option>
                                    <Select.Option value="Upgrade - increased functionality (to DDD, CRT, or ICD)">Upgrade - increased functionality (to DDD, CRT, or ICD)</Select.Option>
                                    <Select.Option value="Downgrade - decreased functionality">Downgrade - decreased functionality</Select.Option>
                                    <Select.Option value="System explant">System explant</Select.Option>
                                    <Select.Option value="Wound revision/generator resite/haematoma">Wound revision/generator resite/haematoma</Select.Option>
                                    <Select.Option value="Lead intervention only">Lead intervention only</Select.Option>
                                    <Select.Option value="Monitor procedure only">Monitor procedure only</Select.Option>
                                    <Select.Option value="Other">Other</Select.Option>
                                </Select>
                                <InsetText>


                                    <Input
                                        id="barcode"
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                handleGetImplantByBarcode(scannedBarcode);
                                            }
                                        }
                                        }
                                        label="Barcode/GTIN"
                                        name="barcode"
                                        type="text"
                                        onChange={(e) => setScannedBarcode(e.target.value)}
                                        required
                                    />
                                    {autoFillDetail && (
                                        <Button
                                            disabled={loadingImplantDetailsByBarcode}
                                            onClick={() => {
                                                handleGetImplantByBarcode(scannedBarcode);
                                            }
                                            }
                                        >
                                            {loadingImplantDetailsByBarcode ? 'Loading...' : 'Auto-fill details'}
                                        </Button>

                                    )
                                    }

                                    {modelNotFound && (
                                        <p style={{ color: 'red', marginTop: '10px' }}>Model not found in the database. Please enter details manually.</p>
                                    )}
                                    <ActionLink
                                        href="/implants"
                                        target="_blank"
                                    >
                                        Register a new model
                                    </ActionLink>


                                </InsetText>

                                <Input
                                    id="modelName"
                                    label="Model Name"
                                    name="modelName"
                                    type="text"
                                    onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, modelName: e.target.value })}
                                    value={updatedImplantInstance?.modelName}
                                    required
                                />
                                <Input
                                    id="modelNumber"
                                    label="Model Number"
                                    name="modelNumber"
                                    type="text"
                                    onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, modelNumber: e.target.value })}
                                    value={updatedImplantInstance?.modelNumber}
                                    required
                                />
                                <Input
                                    id="barcode"
                                    label="GTIN"
                                    name="barcode"
                                    type="text"
                                    onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, barcode: e.target.value })}
                                    value={updatedImplantInstance?.barcode}
                                    required
                                />
                                <Input
                                    id="serialNumber"
                                    label="Serial Number"
                                    name="serialNumber"
                                    type="text"
                                    onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, serialNumber: e.target.value })}
                                    value={updatedImplantInstance?.serialNumber}
                                    required
                                />

                                <Input
                                    id="manufacturer"
                                    label="Manufacturer"
                                    name="manufacturer"
                                    type="text"
                                    onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, manufacturer: e.target.value })}
                                    value={updatedImplantInstance?.manufacturer}
                                    required
                                />
                                <InsetText>
                                    <Select
                                        id="modelCategory"
                                        label="Model Category"
                                        name="modelCategory"
                                        onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, modelCategory: e.target.value })}
                                        value={updatedImplantInstance?.modelCategory}
                                        required
                                    >
                                        <Select.Option value="">Select a model category</Select.Option>

                                        <Select.Option value="Accessory">Accessory</Select.Option>
                                        <Select.Option value="Generator">Generator</Select.Option>
                                        <Select.Option value="Lead">Lead</Select.Option>
                                        <Select.Option value="Implantable Monitor">Implantable Monitor</Select.Option>
                                    </Select>
                                    {isGenerator && (
                                        <div>
                                            <SelectOther
                                                id="generatorImplantSite"
                                                label="Generator Implant Site"
                                                name="generatorImplantSite"
                                                onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, generatorImplantSite: e.target.value })}
                                                value={updatedImplantInstance?.generatorImplantSite}
                                            >
                                                <Select.Option value="">Select a generator implant site</Select.Option>
                                                <Select.Option value="Chest wall - subcutaneous">Chest wall - subcutaneous</Select.Option>
                                                <Select.Option value="Chest wall - submuscular">Chest wall - submuscular</Select.Option>
                                                <Select.Option value="Abdominal - subcutaneous">Abdominal - subcutaneous</Select.Option>
                                                <Select.Option value="Abdominal - submuscular">Abdominal - submuscular</Select.Option>
                                                <Select.Option value="Intravascular - endocardial">Intravascular - endocardial</Select.Option>
                                                <Select.Option value="Intravascular - pulmonary artery">Intravascular - pulmonary artery</Select.Option>
                                            </SelectOther>
                                            <Select
                                                id="maxSystemCapability"
                                                label="Max System Capability"
                                                name="maxSystemCapability"
                                                onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, maxSystemCapability: e.target.value })}
                                                value={updatedImplantInstance?.maxSystemCapability}
                                            >
                                                <Select.Option value="">Select a max system capability</Select.Option>
                                                <Select.Option value="PM-AAI(R)">PM-AAI(R)</Select.Option>
                                                <Select.Option value="CRTD">CRTD</Select.Option>
                                                <Select.Option value="CRTP">CRTP</Select.Option>
                                                <Select.Option value="PM-DDD(R)">PM-DDD(R)</Select.Option>
                                                <Select.Option value="ICD-DR">ICD-DR</Select.Option>
                                                <Select.Option value="ICD-SQ">ICD-SQ</Select.Option>
                                                <Select.Option value="ICD-VR">ICD-VR</Select.Option>
                                                <Select.Option value="Monitor">Monitor</Select.Option>
                                                <Select.Option value="None">None</Select.Option>
                                                <Select.Option value="Other">Other</Select.Option>
                                                <Select.Option value="PM-VVI(R)">PM-VVI(R)</Select.Option>
                                                <Select.Option value="PM-VDD(R) - single lead">PM-VDD(R) - single lead</Select.Option>
                                                <Select.Option value="ICD -VDD(R) - single lead">ICD -VDD(R) - single lead</Select.Option>
                                            </Select>
                                        </div>
                                    )}
                                    {isLead && (
                                        <div>
                                            <Select
                                                id="leadChamber"
                                                label="Lead Chamber"
                                                name="leadChamber"
                                                onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, leadChamber: e.target.value })}
                                                value={updatedImplantInstance?.leadChamber}
                                            >
                                                <Select.Option value="">Select a lead chamber</Select.Option>
                                                <Select.Option value="Right Atrium">Right Atrium</Select.Option>
                                                <Select.Option value="Right Ventricle">Right Ventricle</Select.Option>
                                                <Select.Option value="Left Ventricle">Left Ventricle</Select.Option>
                                                <Select.Option value="NA">NA</Select.Option>
                                            </Select>
                                            <SelectOther
                                                id="pacingSite"
                                                label="Pacing Site"
                                                name="pacingSite"
                                                onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, pacingSite: e.target.value })}
                                                value={updatedImplantInstance?.pacingSite}
                                            >
                                                <Select.Option value="">Select a pacing site</Select.Option>
                                                <Select.Option value="Atrial other">Atrial other</Select.Option>
                                                <Select.Option value="CS - Anterior branch">CS - Anterior branch</Select.Option>
                                                <Select.Option value="CS - Anterolateral branch">CS - Anterolateral branch</Select.Option>
                                                <Select.Option value="CS - Lateral branch">CS - Lateral branch</Select.Option>
                                                <Select.Option value="CS - Posterior branch">CS - Posterior branch</Select.Option>
                                                <Select.Option value="CS - Posterolateral branch">CS - Posterolateral branch</Select.Option>
                                                <Select.Option value="Epicardial (surgical)">Epicardial (surgical)</Select.Option>
                                                <Select.Option value="Greater cardiac vein">Greater cardiac vein</Select.Option>
                                                <Select.Option value="His/Conduction system">His/Conduction system</Select.Option>
                                                <Select.Option value="LA via CS">LA via CS</Select.Option>
                                                <Select.Option value="LV endocardial - trans-septal">LV endocardial - trans-septal</Select.Option>
                                                <Select.Option value="LV epicardial - via pericardium">LV epicardial - via pericardium</Select.Option>
                                                <Select.Option value="LV other">LV other</Select.Option>
                                                <Select.Option value="Middle cardiac vein">Middle cardiac vein</Select.Option>
                                                <Select.Option value="RA endocardial">RA endocardial</Select.Option>
                                                <Select.Option value="RV apex endocardial">RV apex endocardial</Select.Option>
                                                <Select.Option value="RV epicardial (surgical)">RV epicardial (surgical)</Select.Option>
                                                <Select.Option value="RV other">RV other</Select.Option>
                                                <Select.Option value="RV outflow endocardial">RV outflow endocardial</Select.Option>
                                                <Select.Option value="RV septal">RV septal</Select.Option>

                                            </SelectOther>
                                        </div>
                                    )}
                                </InsetText>

                                <Select
                                    id="modelType"
                                    label="Model Type"
                                    name="modelType"
                                    onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, type: e.target.value })}
                                    value={updatedImplantInstance?.type}
                                    required
                                >
                                    <Select.Option value="">Select a model type</Select.Option>
                                    <Select.Option value="LV lead">LV lead</Select.Option>
                                    <Select.Option value="Lead">Lead</Select.Option>
                                    <Select.Option value="Defibrillation Lead">Defibrillation Lead</Select.Option>
                                    <Select.Option value="Single Chamber Defibrillator">Single Chamber Defibrillator</Select.Option>
                                    <Select.Option value="Dual Chamber Defibrillator">Dual Chamber Defibrillator</Select.Option>
                                    <Select.Option value="Cardiac Resynchronisation Therapy Defibrillator">Cardiac Resynchronisation Therapy Defibrillator</Select.Option>
                                    <Select.Option value="Single chamber pacemaker">Single chamber pacemaker</Select.Option>
                                    <Select.Option value="Dual Chamber Pacemaker">Dual Chamber Pacemaker</Select.Option>
                                    <Select.Option value="Cardiac Resynchronisation Therapy Pacemaker">Cardiac Resynchronisation Therapy Pacemaker</Select.Option>
                                    <Select.Option value="Accessory">Accessory</Select.Option>
                                    <Select.Option value="Implantable Monitor">Implantable Monitor</Select.Option>
                                    <Select.Option value="Accessory - Lead Cap">Accessory - Lead Cap</Select.Option>
                                    <Select.Option value="Accessory - Pin Plug">Accessory - Pin Plug</Select.Option>
                                    <Select.Option value="Epicardial lead">Epicardial lead</Select.Option>
                                    <Select.Option value="His Lead">His Lead</Select.Option>

                                </Select>
                                <Select
                                    id="status"
                                    label="Status"
                                    name="status"
                                    onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, status: e.target.value })}
                                    value={updatedImplantInstance?.status}
                                    required
                                >
                                    <Select.Option value="">Select a status</Select.Option>
                                    <Select.Option value="Implanted, connected">Implanted, connected</Select.Option>
                                    <Select.Option value="Explanted">Explanted</Select.Option>
                                    <Select.Option value="Partial extraction">Partial extraction</Select.Option>
                                    <Select.Option value="Complete extraction">Complete extraction</Select.Option>
                                    <Select.Option value="Implanted, abandoned">Implanted, abandoned</Select.Option>

                                </Select>



                                <Select
                                    id="eventType"
                                    label="Event Type"
                                    name="eventType"
                                    onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, eventType: e.target.value })}
                                    value={updatedImplantInstance?.eventType}
                                    required
                                >
                                    <Select.Option value="">Select an event type</Select.Option>
                                    <Select.Option value="Implant">Implant</Select.Option>
                                    <Select.Option value="Revision">Revision</Select.Option>
                                    <Select.Option value="Removal">Removal</Select.Option>
                                    <Select.Option value="Lead Extraction">Lead Extraction</Select.Option>
                                    <Select.Option value="Lead Implant">Lead Implant</Select.Option>
                                    <Select.Option value="Lead Revision">Lead Revision</Select.Option>
                                    <Select.Option value="Lead Removal">Lead Removal</Select.Option>
                                    <Select.Option value="Generator Implant">Generator Implant</Select.Option>
                                    <Select.Option value="Generator Revision">Generator Revision</Select.Option>
                                    <Select.Option value="Generator Removal">Generator Removal</Select.Option>
                                    <Select.Option value="Generator Replacement">Generator Replacement</Select.Option>
                                    <Select.Option value="Other">Other</Select.Option>

                                </Select>


                                <ISODateInput
                                    id="expiryDate"
                                    label="Expiry Date"
                                    name="expiryDate"
                                    value={updatedImplantInstance?.expiryDate}
                                    onChange={(dt) => setUpdatedImplantInstance({ ...updatedImplantInstance, expiryDate: dt })}
                                    required
                                />

                                <Checkboxes name="checkboxes">

                                    <Checkboxes.Box
                                        checked={updatedImplantInstance?.mrConditional}
                                        onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, mrConditional: e.target.checked })}
                                    >
                                        MR Conditional
                                    </Checkboxes.Box>
                                    <Checkboxes.Box
                                        checked={updatedImplantInstance?.abandonedHardware}
                                        onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, abandonedHardware: e.target.checked })}
                                    >
                                        Abandoned Hardware
                                    </Checkboxes.Box>
                                </Checkboxes>
                                <Textarea
                                    id="eventDetails"
                                    label="Event Details"
                                    name="eventDetails"
                                    onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, eventDetails: e.target.value })}
                                    value={updatedImplantInstance?.eventDetails}
                                />
                                <Textarea
                                    id="PractitionerDetails"
                                    label="Practitioner Details"
                                    name="PractitionerDetails"
                                    onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, practitionerDetails: e.target.value })}
                                    value={updatedImplantInstance?.practitionerDetails}
                                />
                                <Textarea
                                    id="Notes"
                                    label="Notes"
                                    name="Notes"
                                    onChange={(e) => setUpdatedImplantInstance({ ...updatedImplantInstance, notes: e.target.value })}
                                    value={updatedImplantInstance?.notes}
                                />


                                {(successCreate || successUpdate) ?
                                    <Callout
                                        label="Success"
                                        header="Success!"
                                        autoDismiss={true}
                                        dismissTime={5000}
                                        time={new Date().toLocaleTimeString()}
                                        title="Success!"
                                        message={successCreate ? "The implantInstance was created successfully." : "The implantInstance was updated successfully."}
                                        mode="success"
                                    />
                                    : null
                                }

                            </Card.Description>
                            <Button
                                disabled={implantInstanceUpdateProcessing}
                                onClick={handleUpdateImplantInstance}
                            >
                                Save changes
                            </Button>

                        </Card.Content>
                    </Card>
                ) : (
                    <Card id='create-card'>
                        <Card.Content>
                            <Card.Heading className="nhsuk-heading-m">
                                Create a new Implant
                            </Card.Heading>
                            <Card.Description>
                                {implantInstanceCreateErrors && (

                                    <ErrorSummary>
                                        <ErrorSummary.Title id="error-summary-title">
                                            There is a problem
                                        </ErrorSummary.Title>
                                        <ErrorSummary.Body>
                                            <ul>
                                                {implantInstanceCreateErrors.map((error, index) => (
                                                    <li key={index}>
                                                        {error}
                                                    </li>
                                                ))}
                                            </ul>
                                        </ErrorSummary.Body>
                                    </ErrorSummary>

                                )}
                                <Checkboxes name="checkboxes">

                                    <Checkboxes.Box
                                        checked={newImplantInstance?.isFirstImplant}
                                        onChange={(e) => setNewImplantInstance({ ...newImplantInstance, isFirstImplant: e.target.checked })}
                                    >
                                        First Implant?
                                    </Checkboxes.Box>
                                </Checkboxes>


                                <ISODateInput
                                    id="eventDate"
                                    label="Implantation Date"
                                    name="eventDate"
                                    value={newImplantInstance?.eventDate}
                                    onChange={(dt) => setUpdatedImplantInstance({ ...newImplantInstance, eventDate: dt })}
                                    required
                                />

                                <Select
                                    id="interventionCategory"
                                    label="Intervention Category"
                                    name="interventionCategory"
                                    onChange={(e) => setNewImplantInstance({ ...newImplantInstance, interventionCategory: e.target.value })}
                                    value={newImplantInstance?.interventionCategory}
                                    required
                                >
                                    <Select.Option value="">Select an intervention category</Select.Option>
                                    <Select.Option value="First ever pacemaker/ICD system">No intervention (FU only of pt implanted elsewhere)</Select.Option>
                                    <Select.Option value="Generator change only">First ever pacemaker/ICD system</Select.Option>
                                    <Select.Option value="New generator and lead(s) revision/replacement">Generator change only</Select.Option>
                                    <Select.Option value="Upgrade - increased functionality (to DDD, CRT, or ICD)">New generator and lead(s) revision/replacement</Select.Option>
                                    <Select.Option value="Downgrade - decreased functionality">Upgrade - increased functionality (to DDD, CRT, or ICD)</Select.Option>

                                    <Select.Option value="System explant">Downgrade - decreased functionality</Select.Option>
                                    <Select.Option value="Wound revision/generator resite/haematoma">System explant</Select.Option>
                                    <Select.Option value="Lead intervention only">Wound revision/generator resite/haematoma</Select.Option>
                                    <Select.Option value="Monitor procedure only">Lead intervention only</Select.Option>
                                    <Select.Option value="Other">Monitor procedure only</Select.Option>
                                </Select>
                                <InsetText>
                                    <Input
                                        id="barcode"
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                handleGetImplantByBarcode(scannedBarcode);
                                            }
                                        }
                                        }
                                        label="Barcode/GTIN"
                                        name="barcode"
                                        type="text"
                                        onChange={(e) => setScannedBarcode(e.target.value)}
                                        required
                                    />
                                    {autoFillDetail && (
                                        <Button
                                            disabled={loadingImplantDetailsByBarcode}
                                            onClick={() => {
                                                handleGetImplantByBarcode(scannedBarcode);
                                            }
                                            }
                                        >
                                            {loadingImplantDetailsByBarcode ? 'Loading...' : 'Auto-fill details'}
                                        </Button>
                                    )
                                    }
                                    {modelNotFound && (
                                        <p style={{ color: 'red', marginTop: '10px' }}>Model not found in the database. Please enter details manually or Register a new model.</p>
                                    )}
                                    <ActionLink
                                        href="/implants"
                                        target="_blank"
                                    >
                                        Register a new model
                                    </ActionLink>


                                </InsetText>
                                <Input
                                    id="modelName"
                                    label="Model Name"
                                    name="modelName"
                                    type="text"
                                    onChange={(e) => setNewImplantInstance({ ...newImplantInstance, modelName: e.target.value })}
                                    value={newImplantInstance?.modelName}
                                    required
                                />
                                <Input
                                    id="modelNumber"
                                    label="Model Number"
                                    name="modelNumber"
                                    type="text"
                                    onChange={(e) => setNewImplantInstance({ ...newImplantInstance, modelNumber: e.target.value })}
                                    value={newImplantInstance?.modelNumber}
                                    required
                                />
                                <Input
                                    id="barcode"
                                    label="GTIN"
                                    name="barcode"
                                    type="text"
                                    onChange={(e) => setNewImplantInstance({ ...newImplantInstance, barcode: e.target.value })}
                                    value={newImplantInstance?.barcode}
                                    required
                                />
                                <Input
                                    id="serialNumber"
                                    label="Serial Number"
                                    name="serialNumber"
                                    type="text"
                                    onChange={(e) => setNewImplantInstance({ ...newImplantInstance, serialNumber: e.target.value })}
                                    value={newImplantInstance?.serialNumber}
                                    required
                                />

                                <Input
                                    id="manufacturer"
                                    label="Manufacturer"
                                    name="manufacturer"
                                    type="text"
                                    onChange={(e) => setNewImplantInstance({ ...newImplantInstance, manufacturer: e.target.value })}
                                    value={newImplantInstance?.manufacturer}
                                    required
                                />
                                <InsetText>

                                    <Select
                                        id="modelCategory"
                                        label="Model Category"
                                        name="modelCategory"
                                        onChange={(e) => setNewImplantInstance({ ...newImplantInstance, modelCategory: e.target.value })}
                                        value={newImplantInstance?.modelCategory}
                                        required
                                    >
                                        <Select.Option value="">Select a model category</Select.Option>

                                        <Select.Option value="Accessory">Accessory</Select.Option>
                                        <Select.Option value="Generator">Generator</Select.Option>
                                        <Select.Option value="Lead">Lead</Select.Option>
                                        <Select.Option value="Implantable Monitor">Implantable Monitor</Select.Option>
                                    </Select>
                                    {isGenerator && (
                                        <div>
                                            <SelectOther
                                                id="generatorImplantSite"
                                                label="Generator Implant Site"
                                                name="generatorImplantSite"
                                                onChange={(e) => setNewImplantInstance({ ...newImplantInstance, generatorImplantSite: e.target.value })}
                                                value={newImplantInstance?.generatorImplantSite}
                                            >
                                                <Select.Option value="">Select a generator implant site</Select.Option>
                                                <Select.Option value="Chest wall - subcutaneous">Chest wall - subcutaneous</Select.Option>
                                                <Select.Option value="Chest wall - submuscular">Chest wall - submuscular</Select.Option>
                                                <Select.Option value="Abdominal - subcutaneous">Abdominal - subcutaneous</Select.Option>
                                                <Select.Option value="Abdominal - submuscular">Abdominal - submuscular</Select.Option>
                                                <Select.Option value="Intravascular - endocardial">Intravascular - endocardial</Select.Option>
                                                <Select.Option value="Intravascular - pulmonary artery">Intravascular - pulmonary artery</Select.Option>
                                            </SelectOther>
                                            <Select
                                                id="maxSystemCapability"
                                                label="Max System Capability"
                                                name="maxSystemCapability"
                                                onChange={(e) => setNewImplantInstance({ ...newImplantInstance, maxSystemCapability: e.target.value })}
                                                value={newImplantInstance?.maxSystemCapability}
                                            >
                                                <Select.Option value="">Select a max system capability</Select.Option>
                                                <Select.Option value="PM-AAI(R)">PM-AAI(R)</Select.Option>
                                                <Select.Option value="CRTD">CRTD</Select.Option>
                                                <Select.Option value="CRTP">CRTP</Select.Option>
                                                <Select.Option value="PM-DDD(R)">PM-DDD(R)</Select.Option>
                                                <Select.Option value="ICD-DR">ICD-DR</Select.Option>
                                                <Select.Option value="ICD-SQ">ICD-SQ</Select.Option>
                                                <Select.Option value="ICD-VR">ICD-VR</Select.Option>
                                                <Select.Option value="Monitor">Monitor</Select.Option>
                                                <Select.Option value="None">None</Select.Option>
                                                <Select.Option value="Other">Other</Select.Option>
                                                <Select.Option value="PM-VVI(R)">PM-VVI(R)</Select.Option>
                                                <Select.Option value="PM-VDD(R) - single lead">PM-VDD(R) - single lead</Select.Option>
                                                <Select.Option value="ICD -VDD(R) - single lead">ICD -VDD(R) - single lead</Select.Option>
                                            </Select>
                                        </div>
                                    )}
                                    {isLead && (
                                        <div>
                                            <Select
                                                id="leadChamber"
                                                label="Lead Chamber"
                                                name="leadChamber"
                                                onChange={(e) => setNewImplantInstance({ ...newImplantInstance, leadChamber: e.target.value })}
                                                value={newImplantInstance?.leadChamber}
                                            >
                                                <Select.Option value="">Select a lead chamber</Select.Option>
                                                <Select.Option value="Right Atrium">Right Atrium</Select.Option>
                                                <Select.Option value="Right Ventricle">Right Ventricle</Select.Option>
                                                <Select.Option value="Left Ventricle">Left Ventricle</Select.Option>
                                                <Select.Option value="NA">NA</Select.Option>
                                            </Select>
                                            <SelectOther
                                                id="pacingSite"
                                                label="Pacing Site"
                                                name="pacingSite"
                                                onChange={(e) => setNewImplantInstance({ ...newImplantInstance, pacingSite: e.target.value })}
                                                value={newImplantInstance?.pacingSite}
                                            >
                                                <Select.Option value="">Select a pacing site</Select.Option>
                                                <Select.Option value="Atrial other">Atrial other</Select.Option>
                                                <Select.Option value="CS - Anterior branch">CS - Anterior branch</Select.Option>
                                                <Select.Option value="CS - Anterolateral branch">CS - Anterolateral branch</Select.Option>
                                                <Select.Option value="CS - Lateral branch">CS - Lateral branch</Select.Option>
                                                <Select.Option value="CS - Posterior branch">CS - Posterior branch</Select.Option>
                                                <Select.Option value="CS - Posterolateral branch">CS - Posterolateral branch</Select.Option>
                                                <Select.Option value="Epicardial (surgical)">Epicardial (surgical)</Select.Option>
                                                <Select.Option value="Greater cardiac vein">Greater cardiac vein</Select.Option>
                                                <Select.Option value="His/Conduction system">His/Conduction system</Select.Option>
                                                <Select.Option value="LA via CS">LA via CS</Select.Option>
                                                <Select.Option value="LV endocardial - trans-septal">LV endocardial - trans-septal</Select.Option>
                                                <Select.Option value="LV epicardial - via pericardium">LV epicardial - via pericardium</Select.Option>
                                                <Select.Option value="LV other">LV other</Select.Option>
                                                <Select.Option value="Middle cardiac vein">Middle cardiac vein</Select.Option>
                                                <Select.Option value="RA endocardial">RA endocardial</Select.Option>
                                                <Select.Option value="RV apex endocardial">RV apex endocardial</Select.Option>
                                                <Select.Option value="RV epicardial (surgical)">RV epicardial (surgical)</Select.Option>
                                                <Select.Option value="RV other">RV other</Select.Option>
                                                <Select.Option value="RV outflow endocardial">RV outflow endocardial</Select.Option>
                                                <Select.Option value="RV septal">RV septal</Select.Option>
                                            </SelectOther>
                                        </div>
                                    )}
                                </InsetText>

                                <Select
                                    id="modelType"
                                    label="Model Type"
                                    name="modelType"
                                    onChange={(e) => setNewImplantInstance({ ...newImplantInstance, type: e.target.value })}
                                    value={newImplantInstance?.type}
                                    required
                                >
                                    <Select.Option value="">Select a model type</Select.Option>
                                    <Select.Option value="LV lead">LV lead</Select.Option>
                                    <Select.Option value="Lead">Lead</Select.Option>
                                    <Select.Option value="Defibrillation Lead">Defibrillation Lead</Select.Option>
                                    <Select.Option value="Single Chamber Defibrillator">Single Chamber Defibrillator</Select.Option>
                                    <Select.Option value="Dual Chamber Defibrillator">Dual Chamber Defibrillator</Select.Option>
                                    <Select.Option value="Cardiac Resynchronisation Therapy Defibrillator">Cardiac Resynchronisation Therapy Defibrillator</Select.Option>
                                    <Select.Option value="Single chamber pacemaker">Single chamber pacemaker</Select.Option>
                                    <Select.Option value="Dual Chamber Pacemaker">Dual Chamber Pacemaker</Select.Option>
                                    <Select.Option value="Cardiac Resynchronisation Therapy Pacemaker">Cardiac Resynchronisation Therapy Pacemaker</Select.Option>
                                    <Select.Option value="Accessory">Accessory</Select.Option>
                                    <Select.Option value="Implantable Monitor">Implantable Monitor</Select.Option>
                                    <Select.Option value="Accessory - Lead Cap">Accessory - Lead Cap</Select.Option>
                                    <Select.Option value="Accessory - Pin Plug">Accessory - Pin Plug</Select.Option>
                                    <Select.Option value="Epicardial lead">Epicardial lead</Select.Option>
                                    <Select.Option value="His Lead">His Lead</Select.Option>
                                </Select>
                                <Select
                                    id="status"
                                    label="Status"
                                    name="status"
                                    onChange={(e) => setNewImplantInstance({ ...newImplantInstance, status: e.target.value })}
                                    value={newImplantInstance?.status}
                                    required
                                >
                                    <Select.Option value="">Select a status</Select.Option>
                                    <Select.Option value="Implanted, connected">Implanted, connected</Select.Option>
                                    <Select.Option value="Explanted">Explanted</Select.Option>
                                    <Select.Option value="Partial extraction">Partial extraction</Select.Option>
                                    <Select.Option value="Complete extraction">Complete extraction</Select.Option>
                                    <Select.Option value="Implanted, abandoned">Implanted, abandoned</Select.Option>

                                </Select>

                                <Select
                                    id="eventType"
                                    label="Event Type"
                                    name="eventType"
                                    onChange={(e) => setNewImplantInstance({ ...newImplantInstance, eventType: e.target.value })}
                                    value={newImplantInstance?.eventType}
                                    required
                                >
                                    <Select.Option value="">Select an event type</Select.Option>
                                    <Select.Option value="Implant">Implant</Select.Option>
                                    <Select.Option value="Revision">Revision</Select.Option>
                                    <Select.Option value="Removal">Removal</Select.Option>
                                    <Select.Option value="Lead Extraction">Lead Extraction</Select.Option>
                                    <Select.Option value="Lead Implant">Lead Implant</Select.Option>
                                    <Select.Option value="Lead Revision">Lead Revision</Select.Option>
                                    <Select.Option value="Lead Removal">Lead Removal</Select.Option>
                                    <Select.Option value="Generator Replacement">Lead Replacement</Select.Option>
                                    <Select.Option value="Generator Implant">Generator Implant</Select.Option>
                                    <Select.Option value="Generator Revision">Generator Revision</Select.Option>
                                    <Select.Option value="Generator Removal">Generator Removal</Select.Option>
                                    <Select.Option value="Generator Replacement">Generator Replacement</Select.Option>
                                    <Select.Option value="Other">Other</Select.Option>
                                </Select>

                                <ISODateInput
                                    id="expiryDate"
                                    label="Expiry Date"
                                    name="expiryDate"
                                    value={newImplantInstance?.expiryDate}
                                    onChange={(dt) => setNewImplantInstance({ ...newImplantInstance, expiryDate: dt })}
                                    required
                                />
                                <Checkboxes name="checkboxes">
                                    <Checkboxes.Box
                                        checked={newImplantInstance?.mrConditional}
                                        onChange={(e) => setNewImplantInstance({ ...newImplantInstance, mrConditional: e.target.checked })}
                                    >
                                        MR Conditional
                                    </Checkboxes.Box>
                                    <Checkboxes.Box
                                        checked={newImplantInstance?.abandonedHardware}
                                        onChange={(e) => setNewImplantInstance({ ...newImplantInstance, abandonedHardware: e.target.checked })}
                                    >
                                        Abandoned Hardware
                                    </Checkboxes.Box>
                                </Checkboxes>
                                <Textarea
                                    id="eventDetails"
                                    label="Event Details"
                                    name="eventDetails"
                                    onChange={(e) => setNewImplantInstance({ ...newImplantInstance, eventDetails: e.target.value })}
                                    value={newImplantInstance?.eventDetails}
                                />
                                <Textarea
                                    id="PractitionerDetails"
                                    label="Practitioner Details"
                                    name="PractitionerDetails"
                                    onChange={(e) => setNewImplantInstance({ ...newImplantInstance, practitionerDetails: e.target.value })}
                                    value={newImplantInstance?.practitionerDetails}
                                />
                                <Textarea
                                    id="Notes"
                                    label="Notes"
                                    name="Notes"
                                    onChange={(e) => setNewImplantInstance({ ...newImplantInstance, notes: e.target.value })}
                                    value={newImplantInstance?.notes}
                                />
                                {(successCreate || successUpdate) && (
                                    <Callout
                                        label="Success"
                                        header="Success!"
                                        autoDismiss={true}
                                        dismissTime={5000}
                                        time={new Date().toLocaleTimeString()}
                                        title="Success!"
                                        message={successCreate ? "The implantInstance was created successfully." : "The implantInstance was updated successfully."}
                                        mode="success"
                                    />
                                )}


                            </Card.Description>

                            <Button
                                disabled={implantInstanceUpdateProcessing}
                                onClick={handleCreateImplantInstance}
                            >
                                Create a new Implant
                            </Button>

                        </Card.Content>
                    </Card>
                )}

            </Modal>

        </React.Fragment>
    );
}

export default ImplantInstanceList;