import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Button, Fieldset, Radios, ActionLink } from "nhsuk-react-components";
import QRCode from "react-qr-code";
import Callout from "../../../components/callout/Callout.component";
import axios from 'axios';
import { createShortUrl, getShortUrlDetailsbyUserId } from "../../../actions/shortUrlActions";
import { listImplantInstancesByPatientNhsNumber } from "../../../actions/implantInstanceActions";
import { listAetiologiesByIds } from '../../../actions/aetiologyActions';
import { listEcgsByIds } from '../../../actions/ecgActions';
import { listSymptomsByIds } from '../../../actions/symptomActions';
import { listFollowupCentresByIds } from '../../../actions/followupCentreActions';
import { listImplantCentresByIds } from '../../../actions/implantCentreActions';
import { extractBase64FromDataUri } from "../../../utils/functions";
import PhysicalCard from '../../../components/physicalCard/PhysicalCard.component';
import Modal from '../../../components/modal/Modal.component';
import { SHORT_URL_CREATE_RESET, SHORT_URL_DETAILS_RESET } from "../../../constants/shortUrlConstants";

const PassGeneration = ({ patient }) => {

    const patientNHSNumber = patient?.nhsNumber;
    const prevPatientNHSNumberRef = useRef();

    const dispatch = useDispatch();
    const [targetDevice, setTargetDevice] = useState("android");
    const [passUrl, setPassUrl] = useState("");
    const [working, setWorking] = useState(false);
    const [sendingEmail, setSendingEmail] = useState(false);
    const [calloutMessage, setCalloutMessage] = useState(null);
    const [printCardModalShow, setPrintCardModalShow] = useState(false);
    const [printModalKey, setPrintModalKey] = useState(0);

    // Redux state selectors
    const implantInstanceListByNhsNumber = useSelector((state) => state.implantInstanceListByNhsNumber);
    const { implantInstances, loading: loadingImplants, error: errorImplants } = implantInstanceListByNhsNumber;

    const aetiologyListByIDs = useSelector((state) => state.aetiologyListByIDs) || {};
    const { aetiologies: selectedAetiologies = [] } = aetiologyListByIDs;

    const ecgListByIDs = useSelector((state) => state.ecgListByIDs) || {};
    const { ecgs: selectedEcgs = [] } = ecgListByIDs;

    const symptomListByIDs = useSelector((state) => state.symptomListByIDs) || {};
    const { symptoms: selectedSymptoms = [] } = symptomListByIDs;

    const followupCentreListByIDs = useSelector((state) => state.followupCentreListByIDs) || {};
    const { followupCentres: selectedFollowupCentres = [] } = followupCentreListByIDs;

    const implantCentreListByIDs = useSelector((state) => state.implantCentreListByIDs) || {};
    const { implantCentres: selectedImplantCentres = [] } = implantCentreListByIDs;

    const { loading: loadingShortUrlCreate, error: errorShortUrlCreate, shortUrl } = useSelector((state) => state.shortUrlCreate);
    const { shortUrl: shortUrlByUserId, loading: loadingShortUrlByUserId } = useSelector((state) => state.shortUrlDetailsByUserId);

    // Effect to handle patient changes
    useEffect(() => {
        if (patient?.id && patientNHSNumber !== prevPatientNHSNumberRef.current) {
            dispatch({ type: SHORT_URL_CREATE_RESET });
            dispatch({ type: SHORT_URL_DETAILS_RESET });
            setPassUrl(""); // Reset passUrl when patient changes
            dispatch(getShortUrlDetailsbyUserId(patient.id));
        }
    }, [dispatch, patient]);

    // Effect to fetch necessary data when patient changes
    useEffect(() => {
        if (patientNHSNumber !== prevPatientNHSNumberRef.current) {
            prevPatientNHSNumberRef.current = patientNHSNumber;
            if (patientNHSNumber) {
                dispatch(listImplantInstancesByPatientNhsNumber(patientNHSNumber));
                dispatch(listAetiologiesByIds(patient.aetiologyIds));
                dispatch(listEcgsByIds(patient.ecgIds));
                dispatch(listSymptomsByIds(patient.symptomIds));
                dispatch(listFollowupCentresByIds(patient.followUpCentreIds));
                dispatch(listImplantCentresByIds(patient.implantCentreIds));
            }
        }
    }, [dispatch, patientNHSNumber]);


    useEffect(() => {
        // Update the key whenever relevant data changes
        setPrintModalKey(prevKey => prevKey + 1);
      }, [patient, implantInstances, selectedAetiologies, selectedSymptoms, selectedEcgs]);

    // Effect to update passUrl when shortUrl or shortUrlByUserId changes
    useEffect(() => {

        if (shortUrl) {
            setPassUrl(`/api/v1/ShortUrl/code/${shortUrl.shortCode}/${targetDevice}`);
        } else if (shortUrlByUserId && shortUrlByUserId.shortCode) {
            setPassUrl(`/api/v1/ShortUrl/code/${shortUrlByUserId.shortCode}/${targetDevice}`);
        } else {
            setPassUrl("");
        }
    }, [shortUrl, shortUrlByUserId, targetDevice]);

    const isDataValid = () => {
        return (
            patient &&
            patient.nhsNumber &&
            patient.dateOfBirth &&
            patient.forename &&
            patient.surname &&
            patient.id &&
            implantInstances &&
            implantInstances.length > 0
        );
    };

    const handleGeneratePass = async () => {
        if (!isDataValid()) {
            setCalloutMessage('Some required patient or implant data is missing. Please ensure all necessary information is provided.');
            return;
        }

        dispatch({ type: SHORT_URL_CREATE_RESET });
        dispatch({ type: SHORT_URL_DETAILS_RESET });

        try {

            setWorking(true);
            setCalloutMessage('Generating a pass ...');

            const generator = implantInstances.find(instance => instance.modelCategory === 'Generator');
            const leads = implantInstances.filter(instance => instance.modelCategory === 'Lead');

            const passData = {
                "email": patient.email,
                "generator_product_type": generator?.type || "N/A",
                "date_of_birth_ddmmyyyy": new Date(patient.dateOfBirth.split('T')[0]).toLocaleDateString('en-GB'),
                "follow_up_centre": selectedFollowupCentres[0]?.name || "N/A",
                "full_name": `${patient.forename} ${patient.surname}`,
                "generator_man": generator?.manufacturer || "N/A",
                "generator_model_name": generator?.modelName || "N/A",
                "generator_implant_date_ddmmyyyy": generator?.eventDate
                    ? new Date(generator.eventDate.split('T')[0]).toLocaleDateString('en-GB')
                    : "N/A",
                "lead0": leads[0]?.modelName || "N/A",
                "lead1": leads[1]?.modelName || "N/A",
                "lead2": leads[2]?.modelName || "N/A",
                "abandoned_hardware": patient.abondonedHardware ? "Yes" : "No",
                "mr_conditional": patient.mrConditional ? "Yes" : "No",
                "patient_id": patient.id,
                "aetiologies": selectedAetiologies.map(a => a.name).join(', '),
                "symptoms": selectedSymptoms.map(s => s.name).join(', '),
                "ecg_indications": selectedEcgs.map(e => e.name).join(', '),
                "implant_centres": selectedImplantCentres.map(ic => ic.name).join(', '),
                "followup_centres": selectedFollowupCentres.map(fc => fc.name).join(', '),
            };

            const shortUrlRequest = {
                longUrl: "",
                userFullName: `${patient.forename} ${patient.surname}`,
                userEmail: patient.email,
                userId: patient.id,
                pkPassFile: null,
                targetDevice: targetDevice,
            };

            const androidResponse = await axios.post("/pass/android", passData, {
                headers: { 'Content-Type': 'application/json' }
            });

            shortUrlRequest.longUrl = androidResponse.data.passUrl;

            const iosResponse = await axios.post("/pass/ios", passData, {
                responseType: 'arraybuffer',
                headers: { 'Content-Type': 'application/json' }
            });

            const pkpassFile = new Blob([iosResponse.data], { type: 'application/vnd.apple.pkpass' });
            const base64data = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(pkpassFile);
                reader.onloadend = function () {
                    resolve(reader.result);
                };
            });

            shortUrlRequest.pkPassFile = extractBase64FromDataUri(base64data);
            dispatch(createShortUrl(shortUrlRequest));
        } catch (error) {
            console.error(error.message);
            setCalloutMessage('An error occurred while generating the pass. Please make sure all required fields are filled.');
        }
        finally {
            setWorking(false);
            setCalloutMessage('Pass generated successfully.');

        }
    };

    const handleSendPassEmail = async () => {
        if (!isDataValid()) {
            setCalloutMessage("Some required data is missing. Please generate the pass first.");
            return;
        }

        if (!passUrl) {
            setCalloutMessage("Please generate the pass before sending an email.");
            return;
        }


        const siteRoot = window.location.origin;
        const cleanPassUrl = passUrl.replace("/android", "").replace("/ios", "");

        const iosUrl = siteRoot + cleanPassUrl + "/ios";
        const androidUrl = siteRoot + cleanPassUrl + "/android";

        const email = {
            "EmailToId": patient.email,
            "EmailToName": `${patient.forename} ${patient.surname}`,
            "EmailSubject": "Your Pass",
            "EmailBody": `<p>Dear ${patient.forename} ${patient.surname},</p><p>Please find your pass links below:</p><h3>Android: <a href='${androidUrl}'>Download for Android</a></h3><h3>iOS: <a href='${iosUrl}'>Download for iOS</a></h3>`,
        }

        try {
            setSendingEmail(true);
            await axios.post("/api/v1/auth/sendemail", email, {
                headers: { 'Content-Type': 'application/json' }
            });
            setCalloutMessage("Email sent successfully!");
        } catch (error) {
            console.error(error);
            setCalloutMessage("Failed to send email: " + error.message);
        } finally {
            setSendingEmail(false);
        }
    }

    const copyToClipboard = () => {
        if (!patient?.id) {
            setCalloutMessage("Patient ID is missing. Cannot generate emergency access link.");
            return;
        }

        const emergencyLink = `${window.location.origin}/patient/emergency/${patient.id}`;
        navigator.clipboard.writeText(emergencyLink);
        setCalloutMessage("Emergency access link copied to clipboard!");
    };

    const openPrintCardModal = () => {
        if (!isDataValid()) {
            setCalloutMessage("Some required data is missing. Please ensure all necessary information is provided before printing.");
            return;
        }

        // Implement the logic to open the print modal
        setPrintCardModalShow(true);
    };

    const closePrintCardModal = () => {
        setPrintCardModalShow(false);
    }


    return (
        <Card style={{ padding: "10px" }}>
            <Card.Description>
                <Fieldset>
                    <Radios name="device" inline id="inline-device" hint="Select the target device.">
                        <Radios.Radio
                            value="android"
                            default
                            checked={targetDevice === "android"}
                            onChange={(e) => setTargetDevice(e.target.value)}
                        >
                            Android
                        </Radios.Radio>
                        <Radios.Radio
                            value="ios"
                            onChange={(e) => setTargetDevice(e.target.value)}
                        >
                            iOS
                        </Radios.Radio>
                    </Radios>
                </Fieldset>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    {passUrl ? (
                        <div>
                            <h3>Scan to Download the Pass</h3>
                            <QRCode
                                size={256}
                                style={{ height: "auto", maxWidth: "100%", width: "100%", cursor: "pointer" }}
                                value={passUrl.includes(window.location.origin) ? passUrl : window.location.origin + passUrl}
                                viewBox={`0 0 256 256`}
                                onClick={() => window.open(passUrl, '_blank')}
                            />
                        </div>
                    ) : loadingShortUrlByUserId ? (
                        <h3>Loading pass information...</h3>
                    ) : (
                        <h3>Generate pass to view QR code</h3>
                    )}
                </div>
            </Card.Description>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    disabled={loadingShortUrlCreate || !isDataValid() || loadingShortUrlByUserId || working}
                     type="submit"
                    onClick={handleGeneratePass}
                >
                    {passUrl ? "Regenerate" : "Generate"}
                </Button>
            </div>
            {errorShortUrlCreate && (
                <Callout
                    label="Error"
                    header="Error!"
                    dismissTime={5000}
                    time={new Date().toLocaleTimeString()}
                    message={errorShortUrlCreate}
                    mode="error"
                />
            )}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ActionLink
                    asElement="a"
                    href="#sendEmail"
                    secondary
                    disabled={sendingEmail || !passUrl || !isDataValid()}
                    onClick={handleSendPassEmail}
                >
                    {sendingEmail ? "Sending..." : "Send Pass by email"}
                </ActionLink>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ActionLink
                    asElement="a"
                    href="#copyToClipboard"
                    disabled={!patient?.id}
                    onClick={copyToClipboard}
                >
                    Copy Emergency Access Link
                </ActionLink>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ActionLink
                    asElement="a"
                    href="#print"
                    disabled={!isDataValid()}
                    onClick={openPrintCardModal}
                >
                    Print
                </ActionLink>
            </div>
            {calloutMessage && (
                <Callout
                    label="Information"
                    header="Message"
                    dismissTime={5000}
                    time={new Date().toLocaleTimeString()}
                    message={calloutMessage}
                    mode="info"
                />
            )}


            <Modal show={printCardModalShow} handleClose={closePrintCardModal}>
                {(patient.nhsNumber && implantInstances) && (
                    <PhysicalCard
                        emailId={patient.email}
                        recipientFullName={`${patient.forename} ${patient.surname}`}
                        nhsNumber={patient.nhsNumber}
                        birthdate={new Date(patient.dateOfBirth.split('T')[0]).toLocaleDateString('en-GB')}
                        etiologies={selectedAetiologies?.map(a => a.name).join(', ') || "-"}
                        symptoms={selectedSymptoms?.map(s => s.name).join(', ') || "-"}
                        ecgIndications={selectedEcgs?.map(e => e.name).join(', ') || "-"}
                        mriConditional={patient.mrConditional ? "Yes" : "No"}
                        generator={implantInstances?.filter(selectedDevice => selectedDevice.modelCategory === "Generator").map(selectedDevice => selectedDevice.modelName) || "-"}
                        leads={implantInstances?.filter(selectedDevice => selectedDevice.modelCategory === "Lead").map(selectedDevice => selectedDevice.modelName) || "-"}
                    />
                )}
            </Modal>
        </Card>

    );

};

export default PassGeneration;