import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Container } from "react-bootstrap";
import { BackLink, Card, Button } from "nhsuk-react-components";
import { getPatientDetails, updatePatient } from "../../actions/patientActions";
import { PATIENT_UPDATE_RESET } from "../../constants/patientConstants";
import { listUsers } from "../../actions/userActions";
import { Steps } from 'antd';
import PatientDemographics from "./common/PatientDemographics";
import ClinicalIndications from "./common/ClinicalIndications";
import ImplantDetails from "./common/ImplantDetails";
import PassGeneration from "./common/PassGeneration";
import NavigationButtons from "./common/NavigationButtons";
import Callout from "../../components/callout/Callout.component";

export default function PatientEditScreen({ history, match }) {
    const dispatch = useDispatch();
    const patientId = match.params._id;
    const [currentStep, setCurrentStep] = useState(0);
    const [updatedPatient, setUpdatedPatient] = useState({});
    const [passGenerationData, setPassGenerationData] = useState({});
    const [implantListData, setImplantListData] = useState({});

    const patientDetails = useSelector((state) => state.patientDetails);
    const { loading, error, patient } = patientDetails;

    const patientUpdate = useSelector((state) => state.patientUpdate);
    const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = patientUpdate;

    const userList = useSelector((state) => state.userList);
    const { loading: loadingUsers, users } = userList;

    useEffect(() => {
        if (!patient || patient.id !== patientId) {
            dispatch(getPatientDetails(patientId));
        } else {
            setUpdatedPatient(patient);
        }
        dispatch(listUsers());
    }, [dispatch, patientId, patient]);

    useEffect(() => {
        if (successUpdate) {
            dispatch({ type: PATIENT_UPDATE_RESET });
            dispatch(getPatientDetails(patientId));
        }
    }, [dispatch, successUpdate, patientId]);

    const handleUpdatedPatientChange = useCallback((updates) => {
        setUpdatedPatient(prevState => ({
            ...prevState,
            ...updates
        }));
    }, []);
    

    const handleSavePatient = () => {
        dispatch(updatePatient(patientId, updatedPatient));
    };

    const steps = [
        {
            title: 'Patient Demographics',
            description: 'Enter patient details',
        },
        {
            title: 'Clinical Indications',
            description: 'Enter clinical details',
        },
        {
            title: 'Implant Details',
            description: 'Enter implant details',
        },
    ];

    const renderCurrentStep = () => {
        const commonProps = {
            updatedPatient,
            loading: loading || loadingUpdate,
            error: error || errorUpdate,
        };

        const renderSaveButton = () => (
            <div className="nhsuk-u-margin-top-2">
                {successUpdate && (
                    <Callout
                        label="Success"
                        header="Success!"
                        dismissTime={5000}
                        time={new Date().toLocaleTimeString()}
                        message="Patient details updated successfully."
                        mode="success"
                    />
                )}
                <Button
                    onClick={handleSavePatient}
                    disabled={loading || loadingUpdate}
                    className="nhsuk-u-margin-top-2"
                >
                    Save Changes
                </Button>
            </div>
        );

        switch (currentStep) {
            case 0:
                return (
                    <>
                        <PatientDemographics
                            {...commonProps}
                            onPatientDemographicChange={handleUpdatedPatientChange}
                        />
                        {renderSaveButton()}
                    </>
                );
            case 1:
                return (
                    <>
                        <ClinicalIndications
                            {...commonProps}
                            onPatientClinicalIndicationChange={handleUpdatedPatientChange}
                        />
                        {renderSaveButton()}
                    </>
                );
            case 2:
                return (
                    <>
                        <ImplantDetails
                            {...commonProps}
                            onPatientImplantDetailsChange={handleUpdatedPatientChange}
                            onDeviceListConditionChange={(implantListData) => { setImplantListData(implantListData) }}
                        />
                        {renderSaveButton()}
                    </>
                );
            default:
                return null;
        }
    };


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="nhsuk-grid-row">
                        <div className="welcome-title d-flex justify-content-between align-items-center">
                            <h3>Edit Patient</h3>
                            <BackLink asElement="a" href="#" onClick={() => window.location.href = '/patients'}>
                                Return To Patient List
                            </BackLink>
                        </div>
                        <div className="nhsuk-grid-column-one-half">
                            <div className="welcome-box">
                                <Steps current={currentStep} items={steps} />
                                <div className="nhsuk-u-padding-top-2">
                                    <Card.Group>
                                        {renderCurrentStep()}
                                    </Card.Group>
                                    <div className="nhsuk-u-margin-top-2">
                                        <NavigationButtons
                                            currentStep={currentStep}
                                            steps={steps}
                                            setCurrentStep={setCurrentStep}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="nhsuk-grid-column-one-third" style={{ display: 'flex', justifyContent: 'center' }}>
                            <PassGeneration patient={updatedPatient} />
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
}
