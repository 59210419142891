import axios from 'axios';

import {
    PATIENT_LIST_REQUEST,
    PATIENT_LIST_SUCCESS,
    PATIENT_LIST_FAIL,

    PATIENT_DETAILS_REQUEST,
    PATIENT_DETAILS_SUCCESS,
    PATIENT_DETAILS_FAIL,

    PATIENT_DELETE_REQUEST,
    PATIENT_DELETE_SUCCESS,
    PATIENT_DELETE_FAIL,

    PATIENT_CREATE_REQUEST,
    PATIENT_CREATE_SUCCESS,
    PATIENT_CREATE_FAIL,

    PATIENT_UPDATE_REQUEST,
    PATIENT_UPDATE_SUCCESS,
    PATIENT_UPDATE_FAIL,

    PATIENT_REQUEST,
    PATIENT_FAIL,
    PATIENT_RESET,
    PATIENT_SUCCESS,

    PATIENT_COUNT_SUCCESS,
    PATIENT_COUNT_REQUEST,
    PATIENT_COUNT_FAIL,

    PATIENT_DTO_REQUEST,
    PATIENT_DTO_SUCCESS,
    PATIENT_DTO_FAIL,
    PATIENT_DTO_RESET,

    SET_CURRENT_PAGE,


} from "../constants/patientConstants";

// use dispatch to dispatch the request and throw it into the reducer
export const listPatients = (filter) => async (dispatch, getState) => {
    try {
        dispatch({ type: PATIENT_LIST_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        let params = {};
        // get the filter
        if (filter) {
            params = filter;
        }

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
            params
        }
        const { data } = await axios.get('/api/v1/patient/all', config);

        dispatch({
            type: PATIENT_LIST_SUCCESS,
            payload: data
        });


    } catch (error) {

        dispatch({
            type: PATIENT_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : "An error occured, please try again later patients",
        });
    }
}

// count patients
export const countPatients = () => async (dispatch, getState) => {
    try {
        dispatch({ type: PATIENT_COUNT_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            },
        }
        const { data } = await axios.get('/api/v1/patient/count', config);

        dispatch({
            type: PATIENT_COUNT_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: PATIENT_COUNT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : "Failed to fetch patient counts",
        });
    }
}



export const getPatientDetails = (_id) => async (dispatch, getState) => {
    try {
        dispatch({ type: PATIENT_DETAILS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        const { data } = await axios.get(`/api/v1/patient/${_id}`, config);

        dispatch({
            type: PATIENT_DETAILS_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: PATIENT_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const getPatientEmergencyDetails = (_id) => async (dispatch, getState) => {
    try {
        dispatch({ type: PATIENT_DETAILS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
            }
        }
        const { data } = await axios.get(`/api/v1/patient/emergency/${_id}`, config);

        dispatch({
            type: PATIENT_DETAILS_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: PATIENT_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}


export const deletePatient = (_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PATIENT_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/api/v1/patient/${_id}/`,
            config
        )

        dispatch({
            type: PATIENT_DELETE_SUCCESS,
        })


    } catch (error) {
        dispatch({
            type: PATIENT_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createPatient = (changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PATIENT_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.post(
            `/api/v1/patient/`,
            changes,
            config
        )

        dispatch({
            type: PATIENT_CREATE_SUCCESS,
            payload: data,
        })

    }

    catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }

        dispatch({
            type: PATIENT_CREATE_FAIL,
            payload: errorMessages,
        })


    }
}

export const loadPatientDto = () => async (dispatch, getState) => {

    try {
        dispatch({
            type: PATIENT_DTO_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const data = await axios.get(
            `/api/v1/patient/patientdto`,
            config
        )

        dispatch({
            type: PATIENT_DTO_SUCCESS,
            payload: data,
        })

    }

    catch (error) {
        dispatch({
            type: PATIENT_DTO_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}





const loadPatientBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            resolve(base64String);
        };
        reader.onerror = (error) => reject(error);
    });
};

// make patient using a local patient
export const makePatient = (file, patientId, apiEndPoint, apiKey) => async (dispatch, getState) => {

    const patient = await loadPatientBase64(file);
    const patient_input = JSON.stringify({ "patient": patient });
    const {
        userLogin: { userInfo },
    } = getState()

    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`
        }
    }

    const data = await axios.post(
        apiEndPoint,
        patient_input,
        config
    )
    try {
        dispatch({
            type: PATIENT_RESET
        })
        await axios({
            method: "POST",
            url: apiEndPoint,

            data: JSON.stringify({ "data": patient }),
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
            dispatch({
                type: PATIENT_SUCCESS
            });
            if (patientId && response.data) {
                dispatch(updatePatient(patientId, { patient: response.data }));
            }
        })
            .catch(function (error) {
                dispatch({
                    type: PATIENT_FAIL
                });
                console.log(error.message);
            });
    }

    catch (error) {
        dispatch({
            type: PATIENT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}


export const updatePatient = (_id, changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PATIENT_UPDATE_REQUEST
        });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        };

        const response = await axios.patch(
            `/api/v1/patient/${_id}/`,
            changes,
            config
        );

        dispatch({
            type: PATIENT_UPDATE_SUCCESS,
            payload: response.data, // Assuming the updated patient data is returned
        });

        dispatch({
            type: PATIENT_DETAILS_SUCCESS,
            payload: response.data, // Assuming the updated patient data is returned
        });
    }
    catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }
        dispatch({
            type: PATIENT_UPDATE_FAIL,
            payload: errorMessages,
        });
    }
};

// update only patient consentIds
// PUT api/v1/patient/{id}/consentIds

export const updatePatientConsentIds = (_id, changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PATIENT_UPDATE_REQUEST
        });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        };

        const response = await axios.patch(
            `/api/v1/patient/${_id}/consentIds`,
            changes,
            config
        );

        dispatch({
            type: PATIENT_UPDATE_SUCCESS,
            payload: response.data, // Assuming the updated patient data is returned
        });

        dispatch({
            type: PATIENT_DETAILS_SUCCESS,
            payload: response.data, // Assuming the updated patient data is returned
        });
    }
    catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }
        dispatch({
            type: PATIENT_UPDATE_FAIL,
            payload: errorMessages,
        });
    }
}


