import React from 'react';

// convert to date from {day:month, month:month, year:year}
// to 2023-12-23T11:25:50Z like format
export function convertToISODate(date) {
    try {
        // Create a UTC date
        const dateObj = new Date(Date.UTC(date.year, date.month - 1, date.day));
        if (isNaN(dateObj.getTime())) {
            throw new Error("Invalid date");
        }
        return dateObj.toISOString();
    } catch (error) {
        // empty date field
        console.error("Date conversion error:", error);
        return null;
    }
}


export function convertToComponentDate(date) {
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();
    const componentDate = {
        day: day,
        month: month,
        year: year,
    };
    return componentDate;
}

export const styles = {
    csvReader: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 10,
    },
    browseFile: {
        width: '20%',
    },
    acceptedFile: {
        border: '1px solid #ccc',
        height: 45,
        lineHeight: 2.5,
        paddingLeft: 10,
        width: '80%',
    },
    remove: {
        borderRadius: 0,
        padding: '0 20px',
    },
    progressBarBackgroundColor: {
        backgroundColor: 'red',
    },
};

// convert JSON to nice HTML 
// the keys are the headers <h3> 
// the values are the values <p>
export function convertJSONToHTML(json) {
    let html = '';
    for (const [key, value] of Object.entries(json)) {
        const beautifiedKey = beautifyKey(key);
        html += `<h3>${beautifiedKey}</h3><p>${value}</p>`;
    }
    return html;
}

function beautifyKey(key) {
    const words = key.split(/(?=[A-Z])/);
    const beautifiedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    return beautifiedWords.join(' ');
}


export function extractBase64FromDataUri(dataUri) {
    const base64Data = dataUri.split(',')[1]; // Split at the comma and get the second part
    return base64Data;
}


// extract gtin expiry date and serial number from the barcode
export function parseBarcode(rawBarcode) {
    try {
        if (!rawBarcode) {
            return {};
        }

        // if raw barcode is 14 chars long, return it as gtin
        if (rawBarcode.length <= 14) {
            return { gtin: rawBarcode, expiry: null, serial: null };
        }

        // GTIN is always 14 chars long starting from index 2
        const gtin = rawBarcode.slice(2, 16);

        // Find the position of "17" after the GTIN
        const dateStartIndex = rawBarcode.indexOf("17", 16);
        
        let expiry = null;
        let serial = null;

        if (dateStartIndex !== -1) {
            // Extract 6 characters after "17" for the date
            const dateStr = rawBarcode.slice(dateStartIndex + 2, dateStartIndex + 8);
            const year = `20${dateStr.slice(0, 2)}`;
            const month = dateStr.slice(2, 4);
            const day = dateStr.slice(4, 6);
            const dateObj = new Date(year, month - 1, day);
            
            if (!isNaN(dateObj.getTime())) {
                expiry = dateObj.toISOString();
            }

            // Find the position of "21" after the date
            const serialStartIndex = rawBarcode.indexOf("21", dateStartIndex + 8);
            if (serialStartIndex !== -1) {
                serial = rawBarcode.slice(serialStartIndex + 2);
            }
        }

        return { gtin, expiry, serial };
    } catch (error) {
        console.error("Error parsing barcode:", error);
        return { gtin: rawBarcode, expiry: null, serial: null };
    }
}


export const convertNewlinesToBreaks = (text) => {
    return text.split('\n').map((line, index, array) => 
        index === array.length - 1 ? line : <React.Fragment key={index}>{line}<br /></React.Fragment>
    );
};
